import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";

import { cn } from "@/lib/utils";
import Spinner from "./spinner";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default:
          "bg-primary text-primary-foreground shadow hover:bg-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90",
        outline:
          "border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
        signup:
          "text-[16px] overflow-visible normal-case  leading-none whitespace-no-wrap bg-[#3b82f6] text-center box-border m-0 font-semibold text-sm rounded text-white border-blue-500 w-full cursor-pointer hover:bg-blue-400",

        stripe:
          "overflow-visible normal-case leading-none whitespace-no-wrap bg-blue-500 text-center box-border m-0 font-semibold text-sm rounded text-white border-blue-500 w-full cursor-pointer hover:bg-blue-400",

        pay: "overflow-visible normal-case leading-none whitespace-no-wrap bg-blue-500 text-center box-border m-0 font-semibold text-sm rounded text-white border-blue-500 w-full cursor-pointer hover:bg-blue-400",

        small:
          "bg-[#3b82f6]  font-semibold text-sm rounded text-white border-blue-500 cursor-pointer hover:bg-blue-400 !px-6 !mt-0",

        createNew:
          "bg-[#4d8eff] !h-11 font-semibold text-[14px] text-white border-[#4d8eff] cursor-pointer hover:bg-blue-400 !mt-0 rounded-3xl flex items-center !py-3 !px-6",

        timeDate:
          "border border-input bg-[#f2f6fc]  hover:text-accent-foreground hover:border-[#737373]/50",

        changeTagCancel: "rounded-sm text-blue-500 hover:text-blue-400 text-sm",
        changeTagApply:
          "rounded-sm border-none text-white bg-blue-500 hover:bg-blue-300 text-sm",
        linkJoyOutline:
          "rounded-sm bg-white text-blue-500 hover:text-white hover:bg-blue-500",
        linkJoyPrimary: "rounded-sm text-white bg-blue-500 hover:bg-blue-300",
        client:
          "text-[#4d8eff] mr-4 hover:bg-blue-400 hover:text-white font-semibold h-8 rounded-sm px-3 py-1.5 inline-flex text-xl items-center justify-between ",
      },
      size: {
        default: "h-9 px-4 py-2",
        sm: "h-8 rounded-md px-3 text-xs",
        lg: "h-10 rounded-md px-8",
        icon: "h-9 w-9",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

const Button = React.forwardRef(
  ({ className, variant, size, isLoading, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        disabled={isLoading} // Simplified disabled attribute
        ref={ref}
        {...props}>
        {isLoading && <Spinner />} {/* Conditionally render Spinner */}
        {props.children} {/* Render children */}
      </Comp>
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
