import * as React from "react";

import { cn } from "@/lib/utils";

const Input = React.forwardRef(({ className, type, ...props }, ref) => {
	return (
		<input
			type={type}
			className={cn(
				"flex h-10 w-full rounded-md border border-[#dcdfe6] bg-[#f7fafd] px-3 py-2 text-sm ring-offset-background file:border-0 hover:border-[#979797] focus-visible:border-blue-400 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground/5 focus-visible:outline-none disabled:cursor-not-allowed font-medium disabled:opacity-50",
				className
			)}
			ref={ref}
			{...props}
		/>
	);
});
Input.displayName = "Input";

export { Input };
